<template>
  <div class="orders">
    <v-row>
      <v-col cols="8">
        <v-card>
          <v-data-table
            v-model="selectedOrder"
            :loading="!localRecord"
            :headers="headers"
            :items="(localRecord && localRecord.orders) || []"
            single-select
            item-key="number"
            class="orders-table"
            @click:row="(item, { isSelected, select }) => select(true)"
          >
            <template #top>
              <v-toolbar flat rounded>
                <v-btn text color="primary" @click="createOrder">
                  {{ $t("new-order") }}
                </v-btn>
                <v-btn
                  text
                  color="error"
                  :disabled="!selectedOrder.length"
                  @click="deleteOrder"
                >
                  {{ $t("delete-order") }}
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  :disabled="!changed"
                  @click="save"
                  :loading="saving"
                >
                  {{ $t("save") }}
                </v-btn>
                <v-btn
                  color="error"
                  text
                  :disabled="!changed || saving"
                  @click="discard"
                >
                  {{ $t("discard") }}
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.number="{ item }">
              {{ item.number + 1 }}
            </template>
            <template v-slot:item.category="{ item }">
              {{ getOptionById(item.category) }}
            </template>
            <template v-slot:item.material="{ item }">
              {{ getOptionById(item.material) }}
            </template>
            <template v-slot:item.shoeSize="{ item }">
              {{
                displayLeftRightNumber(item.shoeSizeLeft, item.shoeSizeRight)
              }}
            </template>
            <template v-slot:item.heelLift="{ item }">
              {{
                displayLeftRightNumber(
                  item.heelLiftLeft,
                  item.heelLiftRight,
                  "mm"
                )
              }}
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip v-if="item.status">
                {{ $t(item.status) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-toolbar class="green lighten-5" flat dense>
            <span class="title">
              {{ $t("details") }}
            </span>
          </v-toolbar>
          <v-card-text v-if="!selectedOrder.length">
            {{
              $t(
                "to-view-details-chose-an-order-from-the-list-or-create-a-new-one"
              )
            }}
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col cols="12">
                <template v-if="selectedOrder[0].design">
                  <konva-designer
                    :order="selectedOrder[0]"
                    :design="selectedOrder[0].design"
                    readonly
                    preview
                    class="mb-2"
                    :threshold="localRecord.threshold"
                    :contrast="localRecord.contrast"
                  />
                  <v-dialog
                    v-model="designEditorDialog"
                    transition="dialog-bottom-transition"
                    fullscreen
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="$store.state.user.type=='tech'" block depressed v-bind="attrs" v-on="on" @click="refreshDesignerIfAlreadyExists()">
                        {{ $t("edit-design") }}
                      </v-btn>
                    </template>
                    <v-card rounded="0">
                      <v-container fluid>
                        <v-row style="height: calc(100vh - 10px)">
                          <v-col cols="1" style="height: 100%; overflow: auto">
                            <pelotta-selector
                              :order="selectedOrder[0]"
                              :design="selectedOrder[0].design"
                              side="left"
                              @update:design="updateDesign"
                            />
                          </v-col>
                          <v-col cols="10" style="height: 100%; overflow: auto; padding: 0px">
                            <konva-designer
                              ref="mainDesigner"
                              :order="selectedOrder[0]"
                              :design="selectedOrder[0].design"
                              @update:design="updateDesign"
                              @quickSave="quickSave"
                              :threshold="localRecord.threshold"
                              :contrast="localRecord.contrast"
                              />
                          </v-col>
                          <v-col cols="1" style="height: 100%; overflow: auto">
                            <pelotta-selector
                              :order="selectedOrder[0]"
                              :design="selectedOrder[0].design"
                              side="right"
                              @update:design="updateDesign"
                            />
                          </v-col>
                          <v-col cols="2">
                            <!-- <pre v-text="selectedOrder[0]"></pre> -->
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    v-if="$store.state.user.type=='tech'"
                    block
                    depressed
                    class="mt-3"
                    @click="downloadPdfDesign()"
                    >
                    {{ $t("download-design") }}
                  </v-btn>
                  <v-btn
                    v-if="$store.state.user.type=='tech'"
                    block
                    depressed
                    color="error"
                    class="mt-3"
                    @click="deleteDesign()"
                  >
                    {{ $t("delete-design") }}
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn v-if="$store.state.user.type=='tech'" block depressed @click="updateDesign({})">
                    {{ $t("add-design") }}
                  </v-btn>
                </template>
              </v-col>
              <v-col cols="6">
                <v-select
                  :label="$t('category')"
                  v-model="selectedOrder[0].category"
                  :items="options.OrderCategoryLocalized"
                  @input="updateObjectValues"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  :label="$t('base-type')"
                  v-model="selectedOrder[0].baseType"
                  :items="options.OrderBaseType"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  :label="$t('material')"
                  v-model="selectedOrder[0].material"
                  :items="options.OrderMaterialLocalized"
                  @input="updateObjectValues"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  :label="$t('cover')"
                  v-model="selectedOrder[0].cover"
                  :items="options.OrderCover"
                />
              </v-col>
              <v-col cols="12">
                <v-dialog
                  v-model="coverSamplesDialog"
                  transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn block depressed v-bind="attrs" v-on="on">{{
                      $t("show-cover-samples")
                    }}</v-btn>
                  </template>
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-toolbar-title>
                        {{ $t("cover-samples") }}
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn icon dark @click="coverSamplesDialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-container fluid>
                      <v-radio-group v-model="selectedOrder[0].cover">
                        <v-row>
                          <v-col
                            cols="2"
                            v-for="cover in localImageList"
                            :key="cover.coverCode"
                          >
                            <v-card
                              @click="
                                selectedOrder[0].cover = cover.coverCode;
                                coverSamplesDialog = false;
                              "
                            >
                              <v-img
                                class="rounded-t"
                                :src="cover.coverImage"
                              />
                              <v-list-item>
                                <v-list-item-title>
                                  {{ cover.coverCode }}
                                </v-list-item-title>
                                <v-list-item-action>
                                  <v-radio :value="cover.coverCode" />
                                </v-list-item-action>
                              </v-list-item>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  type="number"
                  :label="$t('amount')"
                  v-model="selectedOrder[0].count"
                  :suffix="$t('pairs').toLowerCase()"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  :label="$t('status')"
                  v-model="selectedOrder[0].status"
                  :items="options.OrderStatus"
                />
              </v-col>
              <v-col cols="12">
                <v-data-table
                  hide-default-footer
                  :items="[{ name: 'shoe-size' }, { name: 'heel-lift' }]"
                  :headers="[
                    { value: 'name', sortable: false },
                    { text: $t('left'), value: 'left', sortable: false },
                    { text: $t('right'), value: 'right', sortable: false }
                  ]"
                >
                  <template v-slot:item.name="{ item }">
                    {{ $t(item.name) }}
                  </template>
                  <template v-slot:item.left="{ item }">
                    <template v-if="item.name === 'shoe-size'">
                      <v-text-field
                        type="number"
                        hide-details
                        single-line
                        class="pt-0"
                        v-model="selectedOrder[0].shoeSizeLeft"
                        @change="changedshoeSize"
                      />
                    </template>
                    <template v-else>
                      <v-text-field
                        type="number"
                        hide-details
                        single-line
                        class="pt-0"
                        v-model="selectedOrder[0].heelLiftLeft"
                        suffix="mm"
                        @change="changedshoeSize"
                      />
                    </template>
                  </template>
                  <template v-slot:item.right="{ item }">
                    <template v-if="item.name === 'shoe-size'">
                      <v-text-field
                        type="number"
                        hide-details
                        single-line
                        class="pt-0"
                        v-model="selectedOrder[0].shoeSizeRight"
                      />
                    </template>
                    <template v-else>
                      <v-text-field
                        type="number"
                        hide-details
                        single-line
                        class="pt-0"
                        v-model="selectedOrder[0].heelLiftRight"
                        suffix="mm"
                      />
                    </template>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  rows="2"
                  counter
                  :label="$t('comment')"
                  v-model="selectedOrder[0].comment"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="errorDialog" transition="dialog-bottom-transition" max-width="30%">
      <v-toolbar dark color="error">
        <v-toolbar-title>
          {{ $t("error") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card align="center" outlined>
        <br/>
        {{ errorText }}
        <br/><br/>
        <div style="font-size:10px">
        {{ errorDetail }}
        </div>
        <br/><br/><br/>
        <v-btn @click="errorDialog = false" outlined rounded text>
          OK
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import RecordMixin from "@/mixins/RecordMixin";
import OptionsMixin from "@/mixins/OptionsMixin";
import KonvaDesigner from "../components/KonvaDesigner.vue";
import PelottaSelector from "../components/PelottaSelector.vue";

export default {
  name: "Orders",
  mixins: [RecordMixin, OptionsMixin],
  components: { KonvaDesigner, PelottaSelector },
  data: () => ({
    errorDialog : false,
    errorText: "",
    errorDetail:"",
    selectedOrder: [],
    coverSamplesDialog: false,
    designEditorDialog: false
  }),
  mounted() {
    this.$on("discard", () => {
      this.selectedOrder = [];
    });
  },
  computed: {
    headers() {
      return [
        { text: "#", value: "number" },
        { text: this.$t("category"), value: "category" },
        { text: this.$t("base-type"), value: "baseType" },
        { text: this.$t("material"), value: "material" },
        { text: this.$t("shoe-size"), value: "shoeSize" },
        { text: this.$t("heel-lift"), value: "heelLift" },
        { text: this.$t("pairs"), value: "count" },
        { text: this.$t("status"), value: "status" }
      ];
    },
    orders() {
      return (this.localRecord && this.localRecord.orders) || [];
    }
  },
  methods: {
    refreshDesignerIfAlreadyExists() {
      if (this.$refs.mainDesigner) {
        this.$refs.mainDesigner.selectedShapeName = "";
        this.$refs.mainDesigner.update();
      }
    },
    displayLeftRightNumber(left, right, suffix = "") {
      const l = left !== undefined && left !== null ? left + suffix : "?";
      const r = right !== undefined && right !== null ? right + suffix : "?";
      if (parseInt(left) === parseInt(right)) return `${l !== "?" ? l : r}`;
      return `${l} - ${r}`;
    },
    createOrder() {
      if (!this.localRecord.orders)
        this.localRecord = { ...this.localRecord, orders: [] };
      const number =
        this.localRecord.orders.reduce(
          (acc, cur) => Math.max(acc, cur.number),
          -1
        ) + 1;
      this.localRecord.orders.push({
          number,
          status: "order-status-new",
          material: this.OrderMaterialLocalized?.[0]?.value || null,
          heelLiftLeft: "0",
          heelLiftRight: "0"
    });
      this.selectedOrder = [
        this.localRecord.orders[this.localRecord.orders.length - 1]
      ];
    },
    async deleteOrder() {
      if (!this.selectedOrder.length) return;
      if (
        !(await this.$confirm(this.$t("delete-order-confirm"), {
          title: this.$t("warning"),
          buttonTrueText: this.$t("yes"),
          buttonTrueColor: "error",
          buttonFalseText: this.$t("cancel")
        }))
      )
        return;

      const idx = this.localRecord.orders.findIndex(
        o => o.number == this.selectedOrder[0].number
      );
      this.localRecord.orders.splice(idx, 1);
      this.selectedOrder = [];
    },
    updateObjectValues() {
      this.localRecord.orders = this.localRecord.orders.map(o => {
        o.categoryObject = this.getOptionById(o.category, true);
        o.materialObject = this.getOptionById(o.material, true);
        return o;
      });
    },
    updateDesign(design) {
      if (!this.selectedOrder || !this.selectedOrder.length) return;
      let d = design;
      if (d) {
        d = {
          imageUrl: this.localRecord.feetImage,
          imageOpacity: 0.8,
          PatientID: this.localRecord.PatientID,
          pelottas: [],
          ...d
        };
      }

      for (var i = 0; i < this.localRecord.orders.length; i++)
      {
        if (this.localRecord.orders[i].number === this.selectedOrder[0].number)
        {
          this.localRecord.orders[i].design = d;
        }
      }
      
      var orig = this.localRecord;
      this.localRecord = {};
      this.localRecord = orig;
      
      this.selectedOrder[0].design = d;
    },
    async downloadPdfDesign() {
      if (this.changed)
      {
        var resp = await this.$confirm(this.$t("save-design-before-download"), { title: this.$t("warning"), buttonTrueText: this.$t("ok"), buttonTrueColor: "error",buttonFalseText: this.$t("cancel")});
        
        if (resp)
        {
          await this.save();
        }
        
        return;
      }
      
      var response;
      var orderIndex = -1;
      
      console.log(this.selectedOrder.length);
      
      try {
        if (this.selectedOrder.length == 1)
        {
          const idx = this.localRecord.orders.indexOf(this.selectedOrder[0]);
          orderIndex = idx;
          response = await this.$store.dispatch("pdfDesign", {PatientID: this.localRecord.PatientID, OrderIndex: idx});
        }
        else
        {
          response = await this.$store.dispatch("pdfDesign", {PatientID: this.localRecord.PatientID});
        }
        
      } catch (err)
      {
        this.errorText = this.$t("server-communication-error");
        this.errorDetail = String(err);
        this.errorDialog = true;
        
        console.error(err);
        return;
      }
      
      console.log(response);
      
      var PatientID = response.PatientID;
      var pdfURL = response.link;
      
      try {
        response = await fetch(pdfURL);
        var pdfBlob = await response.blob();
        
        const data = window.URL.createObjectURL(pdfBlob);
        var link = document.createElement('a');
        
        link.href = data;
        
        if (orderIndex > -1)
        {
          link.download = PatientID + "_" + this.$t("design") + "_" + (orderIndex+1) + ".pdf";
        }
        else
        {
          link.download = PatientID + "_" + this.$t("design") + ".pdf";
        }
        
        link.onclick = function(){window.URL.revokeObjectURL(pdfBlob);}
        link.click();
      } catch (err)
      {
        window.open(pdfURL);
      }
    },
    async deleteDesign() {
      var resp = await this.$confirm(this.$t("delete-design-confirm"), { title: this.$t("warning"), buttonTrueText: this.$t("yes"), buttonTrueColor: "error",buttonFalseText: this.$t("cancel")});
      
      if (!resp)
      {
        return;
      }
      
      this.updateDesign(null);
    },
    changedshoeSize() {
      //console.log(this.selectedOrder[0].shoeSizeLeft, this.selectedOrder[0].shoeSizeRight);
      //console.log(this.selectedOrder[0]);
      
      if (this.selectedOrder[0].shoeSizeLeft && !this.selectedOrder[0].shoeSizeRight)
      {
        this.$set(this.selectedOrder[0], "shoeSizeRight", this.selectedOrder[0].shoeSizeLeft);
      }
      else if (this.selectedOrder[0].shoeSizeRight && !this.selectedOrder[0].shoeSizeLeft)
      {
        this.$set(this.selectedOrder[0], "shoeSizeLeft", this.selectedOrder[0].shoeSizeRight);
      }
    }
  }
};
</script>

<style lang="scss">
.orders-table tr.v-data-table__selected {
  background: #8080f0 !important;
}
</style>

